// services/enhancedChatService.ts
import { CacheableContentBlock, ContentBlock, Message } from '../types/types';
import { Model } from '../components/chat/ModelSelector';
import { personaStore } from './personaStore';
import { memoryService } from './memoryService';
import { enhancedRelicService } from './RelicService';
import { apiKeyService } from './ApiKeyService';
import { Project } from '../types/project';
import { projectStore } from './projectStore';
import { ragService } from './RAGService';

interface StreamMessageOptions {
  content: string;
  messageHistory?: Message[];
  onChunk: (chunk: string) => void;
  onMemoryCreated?: (memoryId: string) => void;
  model: Model;
  ragContext?: string;
  images?: File[];
  chatId?: string; 
}

class EnhancedChatService {
  private readonly baseUrl = process.env.REACT_APP_API_URL || 
    (window.location.hostname === 'localhost' 
      ? 'http://localhost:8080' 
      : 'allain-express-server-production.up.railway.app');
  private decoder: TextDecoder;

  constructor() {
    this.decoder = new TextDecoder();
  }

  private async convertMessageHistory(messages: Message[]): Promise<any[]> {
    return messages.map(msg => ({
      role: msg.role,
      content: Array.isArray(msg.content) ? msg.content : [{ 
        type: 'text', 
        text: msg.content 
      }]
    }));
  }

  private async* streamResponse(
    response: Response,
    options: StreamMessageOptions
  ): AsyncGenerator<string, void, unknown> {
    if (!response.body) throw new Error('No response body');
    
    const reader = response.body.getReader();
    let buffer = '';
  
    try {
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
  
        const chunk = this.decoder.decode(value, { stream: true });
        buffer += chunk;
        
        // Process complete messages from buffer
        const lines = buffer.split('\n');
        buffer = lines.pop() || ''; // Keep incomplete line in buffer
  
        for (const line of lines) {
          if (line.startsWith('data: ')) {
            try {
              const data = JSON.parse(line.slice(6));
              if (data.type === 'content_block_delta' && 
                  data.delta?.type === 'text_delta' && 
                  data.delta.text) {
                
                  // Just accumulate content and pass through chunks
                  options.onChunk(data.delta.text);
                  yield data.delta.text;
              }
            } catch (e) {
              console.warn('Failed to parse streaming data:', e);
            }
          }
        }
      }
  
      // Process final buffer
      if (buffer) {
        options.onChunk(buffer);
        yield buffer;
      }
  
      return;
    } finally {
      reader.releaseLock();
    }
  }

  // Keep existing helper methods
  private getModelConfig() {
    const currentPersona = personaStore.getSelectedPersona();
    return {
      maxTokens: currentPersona.maxTokens,
      temperature: currentPersona.temperature,
      systemMessage: currentPersona.systemMessage
    };
  }

  private async convertImageToBase64(file: File): Promise<string> {
    // Keep existing image conversion logic
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64 = reader.result as string;
        const base64Data = base64.split(',')[1];
        resolve(base64Data);
      };
      reader.onerror = reject;
      reader.readAsDataURL(file);
    });
  }

  private async getApiKey(): Promise<string> {
    try {
      const apiKey = await apiKeyService.getApiKey();

      if (!apiKey || typeof apiKey !== 'string' || !apiKey.startsWith('sk-')) {
        throw new Error('Invalid API key format');
      }

      return apiKey.trim();
    } catch (error) {
      console.error('Error getting API key in chat service:', error);
      throw new Error('API key not found or invalid. Please add your Anthropic API key in settings.');
    }
  }

  async streamMessage(options: StreamMessageOptions): Promise<Message> {
    const config = this.getModelConfig();
    const currentPersona = personaStore.getSelectedPersona();
    const messageId = Date.now().toString();
    
    // Start memory processing early
    const memoryPromise = currentPersona.cognitiveConfig?.collectMemories 
      ? memoryService.processMessageForMemories(
          { id: messageId, content: options.content, role: 'user', timestamp: new Date() },
          currentPersona.id
        ).then(memoryContent => {
          if (memoryContent && options.onMemoryCreated) {
            const memoryId = Date.now().toString();
            options.onMemoryCreated(memoryId);
          }
          return memoryContent;
        })
      : Promise.resolve(null);
    
    try {

      // Get active projects
      const activeProjects = projectStore.getAllProjects()
        .filter(project => project.isActive);

         // Get base system prompt (with memories if enabled)
      const basePrompt = memoryService.getSystemPromptWithMemories(
        config.systemMessage,
        currentPersona
      );

      // Prepare system message with cached content if enabled
      const systemBlocks = await this.prepareSystemMessage(basePrompt, activeProjects);

      // Get RAG context for projects using RAG
      let ragContext = '';
      const needsRagContext = activeProjects.some(p => 
        p.contextConfig.useRag && 
        (p.contextConfig.strategy === 'rag-only' || p.contextConfig.strategy === 'hybrid')
      );

      if (needsRagContext) {
        ragContext = await ragService.getRelevantContext(options.content);
        if (ragContext) {
          systemBlocks.push({
            type: "text",
            text: ragContext
          });
        }
      }

      // Check if any project uses prompt cache
      const useCache = activeProjects.some(p => 
        p.contextConfig.usePromptCache && 
        (p.contextConfig.strategy === 'cache-only' || p.contextConfig.strategy === 'hybrid')
      );

      // Prepare request headers
      const headers: { [key: string]: string } = {
        'x-api-key': await this.getApiKey(),
        'anthropic-version': '2023-06-01',
        'content-type': 'application/json',
      };

      // Add caching header if any project uses it
      if (useCache) {
        headers['anthropic-beta'] = 'prompt-caching-2024-07-31';
      }

      const messageHistory = await this.convertMessageHistory(options.messageHistory || []);
      
      // Prepare request payload
      const requestPayload = {
        model: options.model.apiId, // Explicitly include the model ID
        messages: messageHistory,
        system: systemBlocks,
        max_tokens: config.maxTokens,
        temperature: config.temperature,
        stream: true
      };
  
      const response = await fetch(`${this.baseUrl}/api/chat`, {
        method: 'POST',
        headers,
        body: JSON.stringify(requestPayload)
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      let fullContent = '';
      let shouldGenerateRelic = false;
  
      // Process the streaming response and collect content
      for await (const chunk of this.streamResponse(response, options)) {
        fullContent += chunk;
      }

      // Extract relic signal if relics are enabled
      if (currentPersona.cognitiveConfig?.useRelics) {
        const { text, shouldGenerateRelic: shouldGenerate } = 
          enhancedRelicService.extractRelicSignal(fullContent);
        
        shouldGenerateRelic = shouldGenerate;
        fullContent = text;
      }
  
      // Create the complete message
      const message: Message = {
        id: messageId,
        content: fullContent,
        role: 'assistant',
        timestamp: new Date(),
        persona: {
          id: currentPersona.id,
          name: currentPersona.name
        },
        model: {
          name: options.model.name,
          id: options.model.id
        }
      };
  
      // Wait for memory processing to complete
      await memoryPromise;
  
      // Generate relic if signaled and enabled
      if (shouldGenerateRelic && currentPersona.cognitiveConfig?.useRelics) {
        try {
          await enhancedRelicService.generateRelic({
            messageContent: fullContent,
            chatId: options.chatId || messageId,
            messageId,
            model: options.model
          });
        } catch (error) {
          console.error('Error generating relic:', error);
        }
      }
  
      return message;
    } catch (error) {
      console.error('Error in streamMessage:', error);
      throw error;
    }
  }

  private async prepareSystemMessage(
    basePrompt: string,
    activeProjects: Project[],
  ): Promise<(ContentBlock | CacheableContentBlock)[]> {
    const systemBlocks: (ContentBlock | CacheableContentBlock)[] = [{
      type: "text",
      text: basePrompt
    }];
  
    // Get projects using cache
    const cachingProjects = activeProjects.filter(p => 
      p.contextConfig.usePromptCache && 
      (p.contextConfig.strategy === 'cache-only' || p.contextConfig.strategy === 'hybrid')
    );
  
    if (cachingProjects.length > 0) {
      const projectContents = await Promise.all(
        cachingProjects.map(async project => {
          const fileContents = await Promise.all(
            project.documents.map(async doc => {
              try {
                const content = await window.fs.readFile(doc.name, { encoding: 'utf8' });
                return `File: ${doc.name}\n${content}`;
              } catch (error) {
                console.warn(`Failed to read ${doc.name}:`, error);
                return '';
              }
            })
          );
      
          const validContents = fileContents.filter(content => content.length > 0);
          return validContents.length > 0 
            ? `Project "${project.name}" Contents:\n${validContents.join('\n\n')}`
            : '';
        })
      );
  
      const validContents = projectContents.filter(content => content.length > 0);
  
      if (validContents.length > 0) {
        systemBlocks.push({
          type: "text",
          text: validContents.join('\n\n==========\n\n'),
          cache_control: { type: "ephemeral" }
        });
      }
    }
  
    return systemBlocks;
  }
}

export const enhancedChatService = new EnhancedChatService();